<template>
  <div
    v-if="resultList.length"
    class="buy__country"
  >
    <div class="buy__title">
      <h3>{{ $t('country.title') }}</h3>
      <CommonAppSearch
        v-if="!$viewport.isLessThan('md')"
        :placeholder="$t('country.placeholder')"
        :search-list="countriesList"
        search-icon
        @onInput="search"
      />
    </div>
    <div class="buy__country_mobile-search">
      <CommonAppSearch
        v-if="$viewport.isLessThan('md')"
        :placeholder="$t('country.placeholder')"
        :search-list="countriesList"
        search-icon
        @onInput="search"
      />
    </div>
    <div class="buy__country__items scroll-customize">
      <div
        v-for="country in resultList"
        :key="country.id"
        :class="{'active':country.id === activeCountry?.id}"
        class="item"
        @click="() => setCurrentCountry(country)"
      >
        <div class="item__title">
          <img
            :src="`${config.baseUrl}${country.img_path}`"
            alt="flag"
          >
          <p>{{ country.title }}</p>
        </div>
        <div class="item__price">
          <p> {{ country.phones }} {{ $t('pcs') }}</p>
          <div v-if="!loadingCountriesIds?.includes(country.id)">
            <p>{{ $t('button.buyFor') }}</p>
            <p>{{ country.price }} &#8381;</p>
          </div>
          <div class="item__price_default-loader" v-if="loadingCountriesIds?.includes(country.id)"><default-loader /></div>
        </div>
        <div
          v-if="$viewport.isLessThan('md') && !loadingCountriesIds?.includes(country.id)"
          class="item__buy-button btn btn-ptimary"
        >
          <div>{{ $t('button.buy') }}</div>
        </div>
        <div
          v-if="$viewport.isLessThan('md') && loadingCountriesIds?.includes(country.id)"
          class="item__buy-button btn btn-ptimary"
        >
          <default-loader />
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="h3"
  >
    {{ $t('empty') }}
  </div>
</template>

<script setup lang="ts">
import type { CountriesListWithPriceType } from '~/server/routes/api-front/prices/by-service/[service]';
import type { AppSearchEmitType } from '~/types/commons';
import DefaultLoader from '~/components/widgets/loaders/DefaultLoader.vue';
const $viewport = useViewport();

const config = useRuntimeConfig().public;
const props = defineProps<{
	countriesList: CountriesListWithPriceType[];
	activeCountry?: CountriesListWithPriceType | null;
  loadingCountriesIds?: number[];
}>();

const emits = defineEmits<{
	selectCountry: [CountriesListWithPriceType]
}>();

function setCurrentCountry (country: CountriesListWithPriceType) {
  emits('selectCountry', country);
}

const countryList = ref<CountriesListWithPriceType[]>([]);
const isInputClear = ref<boolean>(true);

const resultList = computed(() => {
  if (isInputClear.value) {
    return props.countriesList;
  }

  return countryList.value;
});

function search (res: AppSearchEmitType<CountriesListWithPriceType>) {
  countryList.value = res.searchList;
  isInputClear.value = res.isInputClear;
}
</script>

<style scoped lang="scss">
.buy {
	&__country {
		&__items {
			display: flex;
			flex-direction: column;
			padding: 0 8px 0 13px;
			max-height: calc(48px * 5 + 48px);
			overflow-y: auto;

			.item {
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 20px;
				padding: 12px 8px;
				border-bottom: 0.5px solid #D9E3EF;
				cursor: pointer;
				transition: var(--transition);

				&__title {
					display: flex;
					align-items: center;
					gap: 8px;
					color: #283647;
					letter-spacing: -0.24px;

          & img {
            width: 33px;
            height: 24px;
            border-radius: 6px;
          }
				}

				&__price {
					display: flex;
					align-items: center;
					gap: 8px;
					color: var(--primary);
					font-size: 15px;
					font-weight: 500;
					letter-spacing: -0.225px;

					> p {
						font-size: 13px;
						font-weight: 400;
						opacity: 0.4;
					}

					> div {
						display: flex;
						align-items: center;
						gap: 8px;
						padding: 7px 13px 7px 4px;
						border-radius: 6px;
						background: rgba(24, 144, 255, 0.16);

						p {
							&:first-child {
								width: 0;
								height: 0;
								visibility: hidden;
							}
						}
					}
          & .item__price_default-loader {
            min-height: 30px;
            padding-left: 13px;
            background-color: var(--primary);
          }
				}

				&:hover {
					border-radius: 12px;
					background: rgba(215, 235, 255, 0.5);

					.item__price {
						> div {
							padding-left: 13px;
							background: var(--primary-90);
							color: var(--white);
              transition: var(--transition);

              &:hover {
                opacity: 0.8;
              }

							p {
								&:first-child {
									width: auto;
									height: auto;
									visibility: visible;
								}
							}
						}
              & .item__price_default-loader {
                min-height: 30px;
              }
					}
				}
			}
		}
	}

	&__title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 20px;
		margin-bottom: 24px;

		h3 {
			margin: 0;
			color: var(--black);
			font-size: 20px;
			font-weight: 700;
			letter-spacing: -0.3px;
		}

		:deep(input) {
			padding: 6px 5px 7px 5px;
			border: none;
			border-radius: 0;
			border-bottom: 0.5px solid var(--grey-dark);
			font-style: normal;
			font-size: 13px;

			&::placeholder {
				font-style: normal;
				font-size: 13px;
			}
		}
	}

	@media screen and (max-width: 1024px) {
		&__country {
			&__items {
				padding: 0 8px 0 13px;

				.item {
					gap: 16px;
					padding: 10px 6px;

					&__price {
						font-size: 13px;

						> p {
							font-size: 11px;
						}

						> div {
							gap: 6px;
							padding: 5px 10px 5px 2px;
						}
					}

					&:hover {
						.item__price {
							> div {
								padding-left: 10px;
							}
						}
					}
				}
			}
		}

		&__title {
			margin-bottom: 16px;

			h3 {
				font-size: 18px;
			}
		}
	}

  @media screen and (max-width: 860px) {
    &__country_mobile-search {
        :deep(input) {
        border: none;
        border-radius: 10px;
        font-style: normal;
        font-size: 15px;
        background-color: rgba(217, 217, 217, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.1);
        margin-bottom: 43px;

        &::placeholder {
          font-size: 15px;
        }
      }
    }
    &__country {
      &__items {
        max-height: calc(75px * 6 + 75px);

        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 20px;
          padding: 12px 8px;
          border-bottom: 0.5px solid #D9E3EF;
          cursor: pointer;
          transition: var(--transition);

          &__title {
            display: flex;
            align-items: center;
            gap: 8px;
            color: #283647;
            letter-spacing: -0.24px;
            & > p {
              font-size: 18px;
              font-weight: 300;
              line-height: 120%;
              letter-spacing: -1%;
              color: rgba(0, 0, 0, 1);
              &:nth-child(2) {
                display: none;
              }
            }
          }

          &__price {
            //display: none; /** @FIXME для чего? */
            align-items: center;
            gap: 8px;
            color: var(--primary);
            font-size: 15px;
            font-weight: 500;
            letter-spacing: -0.225px;

            > p {
              font-size: 13px;
              font-weight: 400;
              opacity: 0.4;
            }

            > div {
              display: flex;
              align-items: center;
              gap: 8px;
              padding: 7px 13px 7px 4px;
              border-radius: 6px;
              background: rgba(24, 144, 255, 0.16);

              p {
                &:first-child {
                  width: 0;
                  height: 0;
                  visibility: hidden;
                }
              }
            }
          }

          &:hover {
            border-radius: 12px;
            background: rgba(215, 235, 255, 0.5);

            .item__price {
              > div {
                padding-left: 13px;
                background: var(--primary);
                color: var(--white);

                p {
                  &:first-child {
                    width: auto;
                    height: auto;
                    visibility: visible;
                  }
                }
              }
            }
          }
          &__buy-button {
            background-color:rgba(94, 178, 255, 1);
            padding: 10px 7px;
            color: rgba(255, 255, 255, 1);
            border-radius: 8px;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 3%;
            line-height: 120%;
            min-height: 30px;
            min-width: 35px;
          }
        }
      }
    }
  }

	@media screen and (max-width: 768px) {
		&__country {
			&__items {
				padding: 0;
			}
		}
	}
}
</style>
