<template>
  <div
    v-if="!isServicesListLoading"
    class="buy__service"
  >
    <template v-if="servicesList && servicesList.length">
      <div class="buy__title">
        <h3>{{ $t('service.title') }}</h3>
        <CommonAppSearch
          v-if="!$viewport.isLessThan('md')"
          :placeholder="$t('service.placeholder')"
          :search-list="servicesList"
          search-icon
          @onInput="search"
        />
      </div>
      <div class="buy__service_mobile-search">
        <CommonAppSearch
          v-if="$viewport.isLessThan('md')"
          :placeholder="$t('service.placeholder')"
          :search-list="servicesList"
          search-icon
          @onInput="search"
        />
      </div>
      <div class="buy__service__items scroll-customize">
        <div
          v-for="service in resultList"
          :key="service.id"
          :class="{'active':service.id === activeService?.id}"
          class="item"
          @click="setCurrentService(service)"
        >
          <img
            :src="`${config.baseUrl}${service.img_path}`"
            alt="flag"
          >
          <p class="item-title">{{ service.title }}</p>
        </div>
      </div>
    </template>
    <div
      v-else
      class="h2"
    >
      {{ $t('empty') }}
    </div>
  </div>
  <div v-else>
    {{ $t('loading') }}
  </div>
</template>

<script setup lang="ts">
import type { GetServicesApp } from '~/helpers/parsers/ServicesDTO';
import type { AppSearchEmitType } from '~/types/commons';

const $viewport = useViewport();
const config = useRuntimeConfig().public;
const props = defineProps<{
	servicesList: GetServicesApp[] | null;
	activeService?: GetServicesApp | null;
	isServicesListLoading: boolean;
}>();

const emits = defineEmits<{
	selectService: [GetServicesApp]
}>();

function setCurrentService (service: GetServicesApp) {
  emits('selectService', service);
}

const serviceList = ref<GetServicesApp[]>([]);
const isInputClear = ref<boolean>(true);

function search (res: AppSearchEmitType<GetServicesApp>) {
  serviceList.value = res.searchList;
  isInputClear.value = res.isInputClear;
}

const resultList = computed(() => {
  if (isInputClear.value) {
    return props.servicesList;
  }

  return serviceList.value;
});
</script>

<style scoped lang="scss">
.buy {
	&__service {
		&__items {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			//gap: 12px;
			padding: 0 8px 0 18px;
			max-height: calc(35px * 4 + 36px);
			overflow-y: auto;

			& .active.item {
				border: 1px solid var(--primary);
				background: var(--primary-20);
        border-radius: 12px;
			}

			.item {
				flex: 0 1 35%;
				display: flex;
				align-items: center;
				gap: 8px;
				color: #283647;
				letter-spacing: -0.24px;
				cursor: pointer;
				padding: 4px 8px;
				//background: var(--primary-20);
				border-radius: 4px;
				border: 1px solid transparent;
        transition: var(--transition);

				& > p {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

        & > img {
          transition: var(--transition);
          width: 50px;
          height: 35px;
        }

        &:hover {
					border-radius: 12px;
					background: rgba(215, 235, 255, 0.9);
          border: 1px solid var(--primary-90);

          & > img {
            opacity: 0.8;
            transition: var(--transition)
          }

					.item__price {
						> div {
							padding-left: 13px;
							background: var(--primary);
							color: var(--white);

							p {
								&:first-child {
									width: auto;
									height: auto;
									visibility: visible;
								}
							}
						}
					}
				}
			}

		}
	}

	&__title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 20px;
		margin-bottom: 24px;

		h3 {
			margin: 0;
			color: var(--black);
			font-size: 20px;
			font-weight: 700;
			letter-spacing: -0.3px;
		}

		:deep(input) {
			padding: 6px 5px 7px 5px;
			border: none;
			border-radius: 0;
			border-bottom: 0.5px solid var(--grey-dark);
			font-style: normal;
			font-size: 13px;

			&::placeholder {
				font-style: normal;
				font-size: 13px;
			}
		}
	}

	@media screen and (max-width: 1024px) {
		&__service {
			&__items {
				flex-wrap: wrap;
				gap: 8px 30px;
				padding: 0 6px 0 16px;

				.item {
					flex: 0 1 35%;
				}
			}
		}

		&__title {
			margin-bottom: 16px;

			h3 {
				font-size: 18px;
			}
		}
	}

  @media screen and (max-width: 991px) {
    .buy__service_mobile-search{
      :deep(input) {
        margin: 0 0 43px 0;
        border: none;
        border-radius: 10px;
        font-style: normal;
        font-size: 15px;
        background-color: rgba(217, 217, 217, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.1);

        &::placeholder {
          font-size: 15px;
        }
      }
    }
    .buy__service__items {
		  margin-bottom: 20px;
      max-height: calc(51px * 3 + 51px);
      gap: 10px 10px;
      .item {
        flex: 0 1 15%;
        & > p {
          display: none;
        }
      }
    }
  }

	@media screen and (max-width: 768px) {
		&__service {
			&__items {
				padding: 0;
        gap: 10px 10px;
        max-height: calc(51px * 3 + 30px) !important;

				.item {
					flex: 0 1 calc(12% - 10px);
					padding: 0;
				}
			}
		}
	}
}
</style>
