<template>
  <section
    v-if="services && services.length"
    class="buy"
  >
    <div class="wrapper">
      <HomeBuyNumberServicesList
        :services-list="services"
        :active-service="activeService"
        :is-services-list-loading="false"
        @selectService="changeActiveService"
      />
      <HomeBuyNumberCountriesList
        v-if="countriesListForCountry"
        :countries-list="countriesListForCountry"
        :loading-countries-ids="loadingCountriesIds"

        @selectCountry="onByNumber"
      />
      <button type="submit">{{ $t('button.getSMS') }}</button>
    </div>
  </section>
  <div
    v-else
    class="loading-showcase"
  >
    {{ $t('loading') }}
  </div>
</template>

<script setup lang="ts">
import type { GetServicesApp } from '~/helpers/parsers/ServicesDTO';
import type { CountriesListWithPriceType } from '~/server/routes/api-front/prices/by-service/[service]';
import type { ServerResponseCommonType } from '~/types/commons';
import { useUserAuth } from '~/composables/user/useUserAuth';
import { useActivationService } from '~/composables/activation/useActivationService';
import { servicesStore } from '#imports';

const { $toast } = useNuxtApp();
const { currentUser } = useUserAuth();
const { getPhoneNumber } = useActivationService();
const storeService = servicesStore();
const { serviceList } = storeToRefs(storeService);
const activeService = ref<GetServicesApp | null>(null);
const defaultServiceId = 27;

const services = computed(() => {
  return serviceList.value;
});

if (services.value) {
  activeService.value = (services.value.find((t) => t.id === defaultServiceId)) || null;
}

const {
  data: countriesListForCountry,
  refresh: getCountriesListForService,
  pending: isCountriesListForCountry
} = await useCustomFetch<CountriesListWithPriceType[]>(() => `/api-front/prices/by-service/${activeService.value?.sms_activate_org_code}`, {
  immediate: false,
  watch: false,
  baseURL: '/'
});

await getCountriesListForService();

async function changeActiveService (service: GetServicesApp) {
  activeService.value = service;
  await getCountriesListForService();
}

// определяем лоадер загрузки по нажатию на конкретную страну
const loadingCountriesIds: Ref<number[]> = ref([]);
// добавление значения id загрузки в массив
const addIdForLoadingCountriesIds = (id: number) => {
  let _loadingCountriesIds: number[] = [...loadingCountriesIds.value];
  _loadingCountriesIds.push(id);
  loadingCountriesIds.value = _loadingCountriesIds;
}
// удаление значения id загрузки из массива
const removeIdForLoadingCountriesIds = (id: number) => {
  let _loadingCountriesIds: number[] = [...loadingCountriesIds.value];
  const findIndex = _loadingCountriesIds.findIndex((t) => Boolean(t === id));
  _loadingCountriesIds.splice(findIndex, 1);
  loadingCountriesIds.value = _loadingCountriesIds;
}

async function onByNumber (country: CountriesListWithPriceType) {
  if (currentUser) {
    // Устанавливаем собатие загрузки дял кнопки по ID страны
    addIdForLoadingCountriesIds(country?.id);

    const res = await getPhoneNumber({
      service: activeService.value!.id,
      multiple_sms: 0,
      method: 'phone',
      api_key: currentUser.api_token,
      country: country.id
    }).catch((err: ServerResponseCommonType) => {
      console.log(err, 'err');
      $toast.error(err.message);
      removeIdForLoadingCountriesIds(country?.id);
      return null;
    });

    if (res) {
      removeIdForLoadingCountriesIds(country?.id)
      navigateTo('/cabinet');
    }
    return;
  }

  navigateTo('/authorization');
}
</script>

<style scoped lang="scss">
.buy {
	.wrapper {
		display: flex;
		flex-direction: column;
		gap: 28px;
		padding: 20px 22px 14px;
		border-radius: var(--main-border-radius);
		background: rgba(255, 255, 255, 0.80);
		box-shadow: 0px 205px 58px 0px rgba(26, 56, 116, 0.00), 0px 131px 53px 0px rgba(26, 56, 116, 0.01), 0px 74px 44px 0px rgba(26, 56, 116, 0.03), 0px 33px 33px 0px rgba(26, 56, 116, 0.04), 0px 8px 18px 0px rgba(26, 56, 116, 0.05);

		button {
			padding: 12px 24px;
			border-radius: var(--main-border-radius);
			background: rgba(119, 189, 48, 0.90);
			color: var(--white);
			font-size: 20px;
			font-weight: 600;
			line-height: 1.2;
			letter-spacing: 0.1px;
			text-align: center;
			cursor: pointer;
      transition: var(--transition);

      &:hover {
        opacity: 0.8;
      }
		}
	}

	@media screen and (max-width: 1024px) {
		.wrapper {
			gap: 20px;
			padding: 16px 24px 10px;

			button {
				padding: 8px 16px;
				font-size: 18px;
			}
		}
	}
}
</style>
